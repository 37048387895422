import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { BodyMedium, BodySmall } from '@components/styled/Typography';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { ReactComponent as WithdrawalIconComponent } from '@icons/wolfkit-light/withdrawal-with-circle-light.svg';
import { ReactComponent as PortfolioIconComponent } from '@icons/wolfkit-light/copy-portfolio-with-circle-light.svg';
import { ReactComponent as ExchangeIconComponent } from '@icons/wolfkit-light/exchange-with-circle-light.svg';
import { ReactComponent as ArrowIconComponent } from '@icons/wolfkit-light/arrow-left-light.svg';
import Icon from '@shared/ui/icons/Icon';
import ArticleCardTopics from './constants';
import Button from '../../shared/ui/buttons/Button';
// import useAppNavigation from '../../utils/hooks/useAppNavigation'
const ArticleCardContainer = styled(ContainerColumn)(() => ({
    maxWidth: 408,
    background: '#F8F9FA',
    borderRadius: 12,
    padding: '35px 36px 32px 42px',
    height: 'unset',
    boxSizing: 'border-box',
}));
const ArticleCardTitle = styled(BodyMedium)(props => ({
    color: props.theme.palette.text.primary,
    fontSize: 18,
    lineHeight: '26px',
    marginBottom: props.theme.spacing_sizes.s,
    fontWeight: 600,
    letterSpacing: '-0.252px',
}));
const ArticleCardText = styled(BodySmall)(props => ({
    color: props.theme.palette.text.primary,
    fontSize: 16,
    lineHeight: '26px',
    marginBottom: props.theme.spacing_sizes.xs * 1.75,
    letterSpacing: '-0.224px',
}));
const CardIconContainer = styled.div(props => ({
    position: 'relative',
    width: 40,
    height: 36,
    marginBottom: props.theme.spacing_sizes.xm,
}));
const ArticleCardBottomContainer = styled(ContainerRow)(() => ({
    height: 'unset',
    justifyContent: 'space-between',
    alignItems: 'center',
}));
const ArrowIcon = styled(ArrowIconComponent)(() => ({
    transform: 'rotateY(180deg)',
}));
const WithdrawalIcon = styled(WithdrawalIconComponent)(() => ({
    width: 40,
    height: 36,
}));
const PortfolioIcon = styled(PortfolioIconComponent)(() => ({
    width: 40,
    height: 36,
}));
const ExchangeIcon = styled(ExchangeIconComponent)(() => ({
    width: 40,
    height: 36,
}));
const ArticleCardDate = styled(BodyMedium)(props => ({
    fontSize: 14,
    color: props.theme.palette.text.secondary,
    lineHeight: '26px',
}));
const ArticleCard = ({ link, date, title, text, type = undefined, }) => {
    const { t } = useTranslation();
    // const { navigate } = useAppNavigation()
    const IconComponent = useMemo(() => {
        if (type) {
            switch (type) {
                case ArticleCardTopics.COPY_PORTFOLIO:
                    return (_jsx(Icon, { IconComponent: PortfolioIcon, size: 32, keepOriginalColor: true }));
                case ArticleCardTopics.EXCHANGE:
                    return (_jsx(Icon, { IconComponent: ExchangeIcon, keepOriginalColor: true, size: 32 }));
                case ArticleCardTopics.WITHDRAWAL:
                    return (_jsx(Icon, { IconComponent: WithdrawalIcon, keepOriginalColor: true, size: 32 }));
                default:
                    return null;
            }
        }
        return null;
    }, [type]);
    const handleArticleOpen = () => {
        // navigate(link)
    };
    return (_jsxs(ArticleCardContainer, { children: [IconComponent && (_jsx(CardIconContainer, { children: IconComponent })), _jsx(ArticleCardTitle, { children: title }), _jsx(ArticleCardText, { children: text }), _jsxs(ArticleCardBottomContainer, { children: [_jsx(Button, { variant: 'plain', color: 'primary', size: 'small', onClick: handleArticleOpen, endIcon: ArrowIcon, children: t('support.articles.read_article') }), _jsx(ArticleCardDate, { children: format(date, 'dd MMM yyyy') })] })] }));
};
export default ArticleCard;
